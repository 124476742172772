import {
	get_my_balance
	// get_main_balance
} from '@/utils/smartContractCall/walletCall'
import { getCurrContract } from '@/utils/smartContractCall/contractCall'
import { amountTransferByBignumber } from '@/utils/utils'
import { userLogin } from '@/api'

export default {
	namespaced: true,
	state: {
		walletAddress: '',
		chainId: '',
		banlance: '',
		symbol: 'USDT',
		userInfo: {},
		walletConnectSignClient: null,
		isWalletConnect: true
	},
	mutations: {
		SET_WALLET_ADDRESS(state, walletAddress) {
			state.walletAddress = walletAddress || ''
		},
		SET_CHAIN_ID(state, chainId) {
			state.chainId = chainId || ''
		},
		SET_BALANCE(state, banlance) {
			state.banlance = banlance || ''
		},
		SET_SYMBOL(state, symbol) {
			state.symbol = symbol || 'USDT'
		},
		SET_USER_INFO(state, userInfo) {
			state.userInfo = userInfo || {}
		},
		SET_WALLET_CONNECT_SIGN_CLIENT(state, walletConnectSignClient) {
			console.log('walletConnectSignClient', walletConnectSignClient)
			state.walletConnectSignClient = walletConnectSignClient || null
		},
		SET_IS_WALLET_CONNECT(state, isWalletConnect) {
			state.isWalletConnect = isWalletConnect || false
		}
	},
	actions: {
		getAccountBanlacne({ commit, state }, { chainId, token = 'USDT' }) {
			console.log('chainId', chainId, token)
			const contract = getCurrContract(chainId, token)
			// if (String(chainId) === '1') {
			// 	get_main_balance().then(res => {
			// 		commit('SET_BALANCE', amountTransferByBignumber(res))
			// 	})
			// 	return
			// }
			// 获取余额
			get_my_balance(contract).then(res => {
				console.log('banlance', amountTransferByBignumber(res))
				commit('SET_BALANCE', amountTransferByBignumber(res))
			})
		},
		userLogin({ commit, state }) {
			userLogin({
				address: state.walletAddress
			}).then(res => {
				commit('SET_USER_INFO', res)
			})
		}
	}
}
