<template>
	<div
		class="layout-header-container"
		:class="{ 'has-hash': hashList.length }"
	>
		<div v-if="hashList.length" class="hash-container">
			<div class="left">
				VQR Generation
			</div>
			<ul :class="{ marquee_top: animate }">
				<li v-for="(item, index) in hashShowList" :key="index">
					<span class="txtWrap">
						{{ item }}
					</span>
				</li>
			</ul>
			<div class="right">
				Live
			</div>
		</div>
		<div class="layout-header" :style="{top: hashList.length ? '40px' : '0'}">
			<div class="logo" @click="handleToHome">
				<img
					src="@/assets/image/logo.png"
					alt=""
					srcset="" />
			</div>
			<div class="nav-list">
				<div class="nav-item" @click="handleOpen('https://gpdlabs.gitbook.io/gpd_labs')">
					<label>Documents</label>
				</div>
				<div class="nav-item">
					<label>Ecosystem</label>
				</div>
				<div
					class="nav-item"
					@mouseleave="handleHideMedia">
					<label @mouseover="handleHouerMedia">Media</label>
					<div
						:class="{ 'down-show': showMeidia }"
						class="media-dropdown">
						<div class="down-icon">
							<i class="el-icon-caret-bottom" />
						</div>
						<div class="dropdown-list">
							<div class="dropdown-item" @click="handleOpen('https://x.com/GpdLabs')">
								<img
									src="@/assets/image/twitter.png"
									alt="" />
							</div>
							<div class="dropdown-item" @click="handleOpen('https://t.me/GPD_Labs')">
								<img
									src="@/assets/image/telegram.png"
									alt="" />
							</div>
							<div class="dropdown-item" @click="handleOpen('https://medium.com/@gpd_labs')">
								<img
									src="@/assets/image/M.png"
									alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="nav-item">
					<label>Developer</label>
				</div>
			</div>
			<div
				class="conect-wallet"
				@mouseleave="showMoreNetwork = false">
				<template v-if="showConnectWallet">
					<div
						v-if="currNetwork"
						class="network"
						@mouseover="showMoreNetwork = true">
						<img :src="currNetwork.logo" />
						<span>{{ currNetwork.name }}</span>

						<div
							:class="{ 'down-show': showMoreNetwork }"
							class="media-dropdown">
							<div class="down-icon">
								<i class="el-icon-caret-bottom" />
							</div>
							<div
								:style="{
									bottom: `-${networkList.length * 40 + 30}px`
								}"
								class="network-dropdown-list">
								<div
									v-for="(item, index) in networkList"
									:key="index"
									class="network-dropdown-item"
									@click="handleChangeNetwork(item.chainId, item)">
									<img
										:src="item.logo"
										alt="" />
									<span>{{ item.name }}</span>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="!walletAddress"
						class="wallet"
						@click="handleConnectWallet">
						<span>Connect Wallet</span>
						<img src="@/assets/image/header/wallet.png" />
					</div>
					<div
						v-else
						class="wallet wallet-address"
						@click="addressVisible = true">
						<span>
							{{ addressRules(walletAddress) }}
						</span>
					</div>
				</template>
			</div>

			<div class="header-more" @click="popVisible = true">
				<img src="@/assets/image/header/more.png" alt="">
			</div>
		</div>

		<ConnectWalletDialog
			:visible="visible"
			@success="handleConnectWalletSuccess"
			@close="visible = false" />

		<AddressDialog
			:visible="addressVisible"
			@close="addressVisible = false" />

		<van-popup v-model="popVisible" position="top">
			<div class="popup-content" @click.stop="showMoreNetwork = false">
				<div
					v-if="currNetwork"
					class="network"
					@click.stop="showMoreNetwork = true">
					<img :src="currNetwork.logo" />
					<span>{{ currNetwork.name }}</span>

					<div
						:class="{ 'down-show': showMoreNetwork }"
						class="media-dropdown">
						<div class="down-icon">
							<i class="el-icon-caret-bottom" />
						</div>
						<div
							:style="{
								bottom: `-${networkList.length * 30 + 20}px`
							}"
							class="network-dropdown-list">
							<div
								v-for="(item, index) in networkList"
								:key="index"
								class="network-dropdown-item"
								@click="handleChangeNetwork(item.chainId, item)">
								<img
									:src="item.logo"
									alt="" />
								<span>{{ item.name }}</span>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="!walletAddress"
					class="wallet"
					@click="handleConnectWallet">
					<span>Connect Wallet</span>
					<img src="@/assets/image/header/wallet.png" />
				</div>
				<div
					v-else
					class="wallet wallet-address"
					@click="addressVisible = true">
					<span>
						{{ addressRules(walletAddress) }}
					</span>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import ConnectWalletDialog from '@/components/wallet/ConnectWalletDialog.vue'
import AddressDialog from '@/components/wallet/AddressDialog.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import { addressRules } from '@/utils/utils'
import { getLotteryHash } from '@/api'
import { supportNetwork, connectWalletConfig } from '@/utils/config'
import {
	// get_my_account,
	getCurrNetwork,
	watchAccount,
	watchChainId,
	switchNetwork
} from '@/utils/smartContractCall/walletCall'
import SignClient from '@walletconnect/sign-client'
import Web3 from 'web3'
import { Message } from 'element-ui'

export default {
	components: {
		ConnectWalletDialog,
		AddressDialog
	},
	data() {
		return {
			addressRules,
			showMeidia: false,
			showMoreNetwork: false,
			isConnect: false,
			visible: false,
			popVisible: false,
			addressVisible: false,
			timer: null,
			animate: false,
			hashList: [],
			hashShowList: [],
			setInTime: null
		}
	},
	computed: {
		...mapState('app', ['walletAddress', 'chainId', 'walletConnectSignClient', 'isWalletConnect']),
		showConnectWallet() {
			const { name } = this.$route
			return name === 'Intro' || name === 'Purchase'
		},
		currNetwork() {
			const { chainId } = this
			if (!chainId) return ''
			const findItem = supportNetwork.find(item => String(item.chainId) === String(chainId))

			return findItem || ''
		},
		networkList() {
			if (!this.currNetwork) return supportNetwork || []
			const { chainId } = this.currNetwork
			return supportNetwork.filter(e => e.chainId !== chainId)
		}
	},
	watch: {
		walletAddress: {
			async handler(val) {
				if (val) {
					// const res = await get_my_account()
					// if (!res) return
					// if (res !== this.walletAddress) {
					// 	this.SET_WALLET_ADDRESS(res)
					// }
					setTimeout(() => {
						this.watchAccount()
						this.watchChainId()
					}, 500)

					this.getBanlance()
					this.userLogin()
				}
			},
			immediate: true
		},
		popVisible: {
			handler(val) {
				if (!val) {
					this.showMoreNetwork = false
				}
			}
		}
	},
	mounted() {
		// this.getConnectWallet()
		this.getLotteryHash()
	},
	destroyed () {
		clearInterval(this.setInTime) // 页面销毁时清除定时器
	},
	methods: {
		...mapMutations('app', ['SET_WALLET_ADDRESS', 'SET_CHAIN_ID']),
		...mapActions('app', ['getAccountBanlacne', 'userLogin']),
		handleOpen(url) {
			window.open(url)
		},
		getBanlance() {
			this.getAccountBanlacne({
				chainId: this.chainId,
				token: 'USDT'
			})
		},
		getLotteryHash() {
			getLotteryHash().then(res => {
				const { hash_list } = res
				if (!hash_list) return
				this.hashList = [...hash_list]
				this.hashShowList = [...hash_list]
				this.setInTime = setInterval(this.showMarquee, 3000)
			})
		},
		// 滚动栏滚动
		showMarquee () {
			this.animate = true
			setTimeout(() => {
				this.hashShowList.push(this.hashShowList[0])
				this.hashShowList.shift()
				this.animate = false
			}, 500)
		},
		async handleChangeNetwork(chainId, item) {
			if (this.isWalletConnect) {
				const { metadata, projectId } = connectWalletConfig
				const getSignClient = async() => {
					let signClient = null
					if (!signClient) {
						signClient = await SignClient.init({
							projectId, // 替换为你的项目ID
							metadata
						})
					}
					return signClient
				}
				const signClient = await getSignClient()
				const topic = this.walletConnectSignClient
				const {
					chainName,
					nativeCurrency,
					rpc
				} = item
				const switchRes = await signClient.request({
					topic,
					chainId: `eip155:${this.chainId}`,
					request: {
						method: 'wallet_switchEthereumChain',
						params: [{
							chainId: Web3.utils.numberToHex(chainId)
						}]
					}
				}).catch(err => {
					console.log('wallet_switchEthereumChain_Err', err)
					if (err.message) {
						Message.error(err.message)
					}
					const { symbol, decimals, name } = nativeCurrency
					signClient.request({
						topic,
						chainId: `eip155:${this.chainId}`,
						request: {
							method: 'wallet_addEthereumChain',
							params: [
								{
									chainId: Web3.utils.numberToHex(chainId),
									chainName,
									nativeCurrency: {
										name,
										symbol,
										decimals
									},
									rpcUrls: [rpc]
								}
							]
						}
					})
				})
				if (switchRes) {
					this.SET_CHAIN_ID(chainId)
				}
				return
			}
			const swicthRes = await switchNetwork(chainId).catch(_ => {
				this.connectLoading = false
			})
			if (swicthRes) {
				this.SET_CHAIN_ID(chainId)
			}
		},
		watchAccount() {
			watchAccount(newAccount => {
				if (this.walletAddress !== newAccount[0]) {
					this.SET_WALLET_ADDRESS(newAccount[0])
					this.getBanlance()
				}
			})
		},
		watchChainId() {
			watchChainId(async newChainId => {
				const networkId = await getCurrNetwork()
				const findItem = supportNetwork.find(e => String(e.chainId) === String(networkId))

				if (!findItem) {
					this.SET_CHAIN_ID('')
				} else {
					this.SET_CHAIN_ID(networkId)
					this.getBanlance()
				}

				if (this.timer) {
					clearTimeout(this.timer)
					this.timer = null
				}
				this.timer = setTimeout(() => {
					if (!findItem) {
						this.SET_WALLET_ADDRESS('')
					}
				}, 5000)
			})
		},
		handleConnectWalletSuccess() {
			this.visible = false
			this.watchChainId()
		},
		handleConnectWallet() {
			this.visible = true
		},
		handleHouerMedia() {
			this.showMeidia = true
		},
		handleHideMedia() {
			this.showMeidia = false
		},
		handleToHome() {
			this.$router.push({
				name: 'Home'
			})
		}
	}
}
</script>

<style scoped lang="less">
.layout-header-container {
	// min-height: 120px;
}
.has-hash {
	min-height: 40px;
}

	@keyframes dropdown {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	.hash-container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
		background-color: #3c11da;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		font-size: 12px;
		overflow: hidden;
		.left {
			width: 120px;
			height: 26px;
			border: 1px solid #fff;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		ul {
			width: 600px;
			height: 100%;
			margin: 0 20px;
			li {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
			}
		}
		.marquee_top {
			transition: all 0.5s linear;
			// animation-fill-mode: forwards;
			margin-top: -40px; /* 容器高度 */
		}
		.right {
			width: 60px;
			height: 26px;
			border: 1px solid #fff;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			&::before {
				content: '';
				width: 6px;
				height: 6px;
				background-color: rgb(212, 212, 8);
				border-radius: 50%;
				margin-right: 3px;
			}
		}
	}
.layout-header {
	width: 100%;
	font-family: 'Homenaje-Regular';
	// height: 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 0;
	// background-color: transparent;
	background-color: #000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	img {
		display: block;
	}

	.logo {
		width: 350px;
		padding-left: 90px;

		img {
			width: 130px;
			cursor: pointer;
		}
	}
	.media-dropdown {
		display: none;
		&.down-show {
			display: block;
			animation: dropdown 0.5s linear forwards;
			animation-fill-mode: forwards;
		}
	}
	.network {
		position: relative;
		.network-dropdown-list {
			position: absolute;
			z-index: 999;
			left: 50%;
			bottom: -54px;
			width: 160px;
			margin-left: -80px;
			.network-dropdown-item {
				width: 160px;
				height: 32px;
				border-radius: 40px;
				border: 1px solid #fff;
				background: #000;
				font-size: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				cursor: pointer;
				margin-bottom: 10px;
				img {
					width: 22px;
					height: 22px;
				}
				&.wallet-address {
					font-size: 14px;
					cursor: auto;
				}
			}
		}
	}
	.down-icon {
		position: absolute;
		left: 0;
		bottom: -25px;
		width: 100%;
		height: 25px;
		line-height: 30px;
		font-size: 16px;
		color: #fff;
		text-align: center;
		// opacity: 0.6;
	}
	.nav-list {
		display: flex;
		align-items: center;
		.nav-item {
			margin-right: 52px;
			color: #fff;
			font-size: 20px;
			position: relative;
			label {
				cursor: pointer;
			}
			&:nth-last-child(1) {
				margin-right: 0;
			}
			.dropdown-list {
				position: absolute;
				z-index: 999;
				left: 50%;
				bottom: -54px;
				width: 110px;
				margin-left: -55px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: transparent;
				cursor: pointer;
				.dropdown-item {
					flex: 1;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 22px;
						// height: 100%;
					}
					&:nth-last-child(1) img {
						width: 16px;
					}
				}
			}
		}
	}

	.header-more {
		display: none;
	}
	.conect-wallet {
		width: 350px;
		padding-right: 90px;
		display: flex;
		justify-content: flex-end;
		.network,
		.wallet {
			flex-shrink: 0;
			width: 160px;
			height: 32px;
			border-radius: 40px;
			border: 1px solid #fff;
			font-size: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			cursor: pointer;
			&.wallet-address {
				font-size: 14px;
			}
		}
		.network {
			margin-right: 20px;
			img {
				width: 22px;
				height: 22px;
				margin-right: 10px;
			}
		}
		.wallet {
			img {
				width: 22px;
				height: 22px;
				margin-left: 10px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.hash-container {
		height: 40px;
		font-size: 11px;
		padding: 0 10px;
		.left {
			width: 100px;
			height: 26px;
			border: 1px solid #fff;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		ul {
			flex: 1;
			width: 0;
			height: 100%;
			margin: 0 10px;
			li {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				span {
					width: 100%;
					word-wrap: break-word;
					display: block;
				}
			}
		}
		.marquee_top {
			transition: all 0.5s linear;
			// animation-fill-mode: forwards;
			margin-top: -40px; /* 容器高度 */
		}
		.right {
			width: 50px;
			border: 1px solid #fff;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			&::before {
				content: '';
				width: 6px;
				height: 6px;
				background-color: rgb(212, 212, 8);
				border-radius: 50%;
				margin-right: 3px;
			}
		}
	}
	.popup-content {
		width: 100%;
		padding: 30px 24px;
		height: 160px;
		background-color: #555;
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #555;
		.network,
		.wallet {
			width: 120px;
			height: 24px;
			border-radius: 12px;
			border: 1px solid #fff;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			cursor: pointer;
			&.wallet-address {
				font-size: 12px;
				cursor: auto;
			}
		}

		.media-dropdown {
			display: none;
			&.down-show {
				display: block;
				animation: dropdown 0.5s linear forwards;
				animation-fill-mode: forwards;
			}
		}
		.network {
			margin-right: 10px;
			img {
				width: 14px;
				height: 14px;
				margin-right: 6px;
			}
		}
		.wallet {
			img {
				width: 14px;
				height: 14px;
				margin-left: 6px;
			}
		}

		.network {
			position: relative;
			.network-dropdown-list {
				position: absolute;
				z-index: 999;
				left: 50%;
				bottom: -24px;
				width: 120px;
				margin-left: -60px;
				.network-dropdown-item {
					width: 100%;
					height: 24px;
					border-radius: 20px;
					border: 1px solid #fff;
					font-size: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					cursor: pointer;
					margin-bottom: 6px;
					img {
						width: 14px;
						height: 14px;
					}
					&.wallet-address {
						font-size: 12px;
						cursor: auto;
					}
				}
			}
		}
	}

	.layout-header {
		padding: 20px 24px;
		padding-right: 0;
		align-items: center;


		.down-icon {
			position: absolute;
			left: 0;
			bottom: -14px;
			width: 100%;
			height: 20px;
			line-height: 30px;
			font-size: 12px;
			color: #fff;
			text-align: center;
			// opacity: 0.6;
		}
		.logo {
			width: 90px;
			padding-left: 0;
			flex-shrink: 0;

			img {
				width: 70px;
			}
		}


		.nav-list {
			display: flex;
			align-items: center;
			margin-left: auto;
			.nav-item {
				margin-right: 20px;
				font-size: 12px;
				.dropdown-list {
					bottom: -36px;
					width: 70px;
					margin-left: -35px;
					.dropdown-item {
						flex: 1;
						height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							width: 14px;
							// height: 100%;
						}
						&:nth-last-child(1) img {
							width: 12px;
						}
					}
				}
			}
		}
		.conect-wallet {
			display: none;
		}
		.header-more {
			width: 40px;
			display: flex;
			justify-content: flex-end;
			padding-right: 10px;
			img {
				width: 14px;
				height: 14px;
			}
		}
	}
}
</style>
