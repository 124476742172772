export const supportWallet = [
	{
		name: 'MetaMask',
		logo: require('@/assets/image/header/metamask.png')
	},
	{
		name: 'OKX Wallet',
		logo: require('@/assets/image/header/metamask.png')
	}
	// {
	// 	name: 'Wallet Connect',
	// 	logo: require('@/assets/header/metamask.png')
	// }
]
export const supportNetwork = [
	{
		name: 'ethereum',
		sortName: 'eth',
		chainName: 'Ethereum Smart Chain',
		chainId: 1,
		nativeCurrency: {
			name: 'ETH',
			symbol: 'ETH',
			decimals: 18
		},
		USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
		USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
		logo: require('@/assets/image/header/eth.png')
	},
	{
		name: 'Arbitrum',
		sortName: 'arb',
		chainName: 'Arbitrum One Mainnet',
		chainId: 42161,
		nativeCurrency: {
			name: 'Arbitrum',
			symbol: 'ETH',
			decimals: 18
		},
		USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
		USDC: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
		rpc: 'https://arb1.arbitrum.io/rpc',
		logo: require('@/assets/image/header/arb.png')
	},
	{
		name: 'Polygon',
		sortName: 'POL',
		chainName: 'Polygon Mainnet',
		chainId: 137,
		nativeCurrency: {
			name: 'Polygon',
			symbol: 'POL',
			decimals: 18
		},
		USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
		USDC: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
		rpc: 'https://polygon-rpc.com/',
		logo: require('@/assets/image/header/py.png')
	},
	{
		name: 'BSC',
		sortName: 'eth',
		chainName: 'BNB Smart Chain Testnet',
		chainId: 97,
		nativeCurrency: {
			name: 'BNB',
			symbol: 'BNB',
			decimals: 18
		},
		USDT: '0x9c10297bC70fE8298b5Dd2eb5F286E61B5273ceD',
		USDC: '0x205f00DD3De8238799c98236D3C3EEd48aFc6AA2',
		rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
		logo: require('@/assets/image/header/eth.png')
	},
]
export const connectWalletConfig = {
	metadata: {
		//项目名称
		name: 'GPD Labs',
		//项目解释
		description: 'GPD Labs',
		//项目官网
		url: window.origin,
		//项目图片
		icons: [
			window.origin + '/logo.png'
		]
	},
	projectId: "4087b80ead97e4f9ac9c8d6f2aacdc73"
}
