<template>
	<Select
		v-bind="$attrs"
		:autocomplete="$attrs.autocomplete || 'new-text'"
		:placeholder="$attrs.placeholder || '请选择'"
		v-on="$listeners">
		<slot />
	</Select>
</template>

<script>
import { Select } from 'element-ui'
export default {
	components: {
		Select
	},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
