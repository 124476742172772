import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

// 异步路由
import asyncRoutes from './asyncRoutes'
// 同步路由
import commonRoutes from './commonRoutes'

Vue.use(VueRouter)

const routes = [...asyncRoutes, ...commonRoutes]

// 解决VueRouter报错 Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
	return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
	return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
	next()
})

router.afterEach((to, from) => {
	window.scrollTo(0, 0)
})

export default router
