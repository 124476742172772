<template>
	<el-dialog
		:visible="visible"
		:close-on-click-modal="true"
		:show-close="true"
		class="my-info-dialog"
		@close="$emit('close')">
		<div class="content">
			<h1 class="address">
				{{ addressRules(walletAddress) }}
			</h1>
			<h2>
				0 USD
			</h2>
			<div class="cubes">
				<div>
					<span>You have :</span>
					<img src="@/assets/image/logo1.png" />
				</div>
				<p>0 GPD Cubes</p>
			</div>
			<div class="will-recevie">
				<div>You will receive :</div>
				<p>0 Tokens</p>
			</div>

			<div class="order-area">
				<h3>Order History</h3>
				<div class="order-list">
					<div
						v-for="(item, index) in 6"
						:key="index"
						class="order-item">
						<span>You bought 3 GPD Cubes</span>
						<span>Aug 23, 2024</span>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { addressRules } from '@/utils/utils'
import { Message } from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import Vue from 'vue'

Vue.use(VueClipboard)

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			addressRules
		}
	},
	computed: {
		...mapState('app', ['walletAddress', 'chainId'])
	},
	mounted() {

	},
	methods: {
		...mapMutations('app', ['SET_WALLET_ADDRESS', 'SET_CHAIN_ID']),
		handleDisconnect() {
			this.SET_WALLET_ADDRESS('')
			this.SET_CHAIN_ID('')
			this.$emit('close')
		},
		handleCopy() {
			Message.success('Copy Success')
		}
	}
}
</script>
<style lang="less" scoped>
.my-info-dialog {
	font-family: 'Homenaje-Regular';
	::v-deep .el-dialog {
		margin-top: 30vh;
		.el-dialog__header {
			padding: 0;
		}
		width: 360px;
		height: 470px;
		background: linear-gradient(180deg, #292929 63%, #0d0d0d 100%);
		border-radius: 20px;
		border: 1px solid #fff;
		.el-dialog__body {
			width: 100%;
			height: 100%;
			padding: 0;
		}
		.content {
			width: 100%;
			height: 100%;
			padding: 30px 24px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #fff;

			h1 {
				font-size: 32px;
				flex-shrink: 0;
			}
			h2 {
				color: #A49D9D;
				font-size: 26px;
				margin: 20px 0 35px;
				flex-shrink: 0;
			}
			.cubes {
				padding: 0 15px;
				width: calc(100% - 30px);
				font-size: 26px;
				flex-shrink: 0;
				> div {
					display: flex;
					align-items: center;
					margin-bottom: 14px;
					img {
						width: 30px;
						height: 30px;
						margin-left: 8px;
					}
				}
			}
			.will-recevie {
				padding: 0 15px;
				width: calc(100% - 30px);
				font-size: 26px;
				margin: 22px 0 11px;
				flex-shrink: 0;
				> div {
					margin-bottom: 14px;
				}
			}
			.order-area {
				flex: 1;
				overflow-y: scroll;
				height: 0;
				padding: 10px 15px 0;
				width: calc(100% - 30px);
				border-top: 1px solid #555;

				h3 {
					font-size: 16px;
					margin-bottom: 12px;
				}
				.order-list {
					padding-right: 20px;
					width: 100%;
					.order-item {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 6px;
						span {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.my-info-dialog {
		::v-deep .el-dialog {
			margin-top: 60px;
			width: 240px;
			height: 360px;
			.content {
				padding: 30px 12px 20px;

				h1 {
					font-size: 18px;
					flex-shrink: 0;
				}
				h2 {
					color: #A49D9D;
					font-size: 14px;
					margin: 10px 0 12px;
					flex-shrink: 0;
				}
				.cubes {
					padding: 0 8px;
					width: calc(100% - 16px);
					font-size: 14px;
					flex-shrink: 0;
					> div {
						display: flex;
						align-items: center;
						margin-bottom: 8px;
						img {
							width: 16px;
							height: 16px;
							margin-left: 4px;
						}
					}
				}
				.will-recevie {
					padding: 0 8px;
					width: calc(100% - 16px);
					font-size: 14px;
					margin: 11px 0 6px;
					flex-shrink: 0;
					> div {
						margin-bottom: 7px;
					}
				}
				.order-area {
					flex: 1;
					overflow-y: scroll;
					height: 0;
					padding: 5px 8px 0;
					width: calc(100% - 16px);
					border-top: 1px solid #555;

					h3 {
						font-size: 14px;
						margin-bottom: 8px;
					}
					.order-list {
						padding-right: 10px;
						width: 100%;
						.order-item {
							width: 100%;
							margin-bottom: 4px;
							span {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
