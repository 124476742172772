import store from '@/store'
import Web3 from 'web3'
import { supportNetwork } from '@/utils/config'
// import ethers from 'ethers'
// https://juejin.cn/post/7160922117591007263
const new_web3 = (window.new_web3 = new Web3(Web3.givenProvider || null))

export const get_my_account = () => {
	return new Promise((resolve, reject) => {
		new_web3.givenProvider.enable().then(res_accounts => {
			if (!res_accounts) {
				reject('err')
			}
			resolve(res_accounts[0])
		})
	})
}

// 添加网络
export const addNetwork = (chainId) => {
	const findItem = supportNetwork.find(e => e.chainId === Number(chainId))
	const { chainName, nativeCurrency, rpc } = findItem
	const { symbol, decimals, name } = nativeCurrency

	return new Promise((resolve, reject) => {
		try {
			window.ethereum &&
			window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						chainId: Web3.utils.numberToHex(chainId),
						chainName,
						nativeCurrency: {
							name,
							symbol,
							decimals
						},
						rpcUrls: [rpc]
					}
				]
			}).then(res => {
				console.log('add_res', res)
				resolve(true)
			})
			.catch(err => {
				console.log('add_err', err)
				reject(err)
			})
		} catch (err) {
			console.log('catch_err', err)
			reject(err)
		}
	})
}

// 获取当前网络
export const getCurrNetwork = () => {

	return new Promise((resolve, reject) => {
		new_web3.eth.net.getId((error, result) => {
			console.log(error, result)
			if (!error) {
				resolve(result)
			} else {
				reject(error)
			}
		})
	})
}

// 切换网络
export const switchNetwork = async chainId => {
	console.log('chainId', chainId)
	return new Promise((resolve, reject) => {
		try {
			window.ethereum
			.request({
				method: 'wallet_switchEthereumChain',
				params: [
					{
						chainId: Web3.utils.numberToHex(chainId),
					}
				]
			})
			.then(res => {
				resolve(chainId)
			})
			.catch(err => {
				console.log('change_err', err)
				addNetwork(chainId).then(res => {
					// switchNetwork(chainId)
				}).catch(error => {
					console.log('add_err_try', error)
				})
				// if (err.code === 4902) {
				// 	addNetwork(chainId).then(res => {
				// 		// switchNetwork(chainId)
				// 	}).catch(error => {
				// 		console.log('add_err_try', error)
				// 	})
				// } else {
				// 	reject(err)
				// }
			})
		} catch (err) {
			if (err.code === 4902) {
				addNetwork(chainId).then(res => {
					switchNetwork(chainId)
				}).catch(_ => {
					//
				})
			}
		}
	})
}

// 网络监听
export const watchChainId = callBack => {
	window.ethereum.on('chainChanged', chainId => {
		// 正确处理链更改之后的业务流程可能很复杂。官方建议链更改只有重新加载页面
		if (callBack) {
			callBack(chainId)
		}
	})
}

// 账户监听
export const watchAccount = callBack => {
	window.ethereum.on('accountsChanged', accounts => {
		// 监听到账号变化，重新获取账号
		if (callBack) {
			callBack(accounts)
		}
	})
	// new_web3.eth.accountsChanged((error, accounts) => {
  //   if (error) {
	// 		console.log(error);
  //   } else {
	// 		callBack(accounts)
  //   }
// })
}

export const get_main_balance = async () => {
	const account = store.state.app.walletAddress
	const res = await new_web3.eth.getBalance(account)

	return res
}

export const get_my_balance = async contract => {
	const account = store.state.app.walletAddress
	console.log(account, contract)
	return new Promise((resolve, reject) => {
		contract.methods.balanceOf(account).call((err, res_balance) => {
			if (err) {
				console.log('get_my_balance_err', err)
				reject(err)
				return
			}
			resolve(res_balance)
		})
	})
}

export const isAddress = address => {
	return Web3.utils.isAddress(address)
}

export const gasPrice = () => {
	return new_web3.eth.gasPrice
}

export default new_web3
