<template>
	<div class="tab">
		<ul
			ref="tabWrapper"
			class="tab-wrapper">
			<li
				v-for="item in tabData"
				:key="item.value"
				class="tab-item"
				@click="handleToggleTab(item)">
				<p
					class="content"
					:data-value="item.value">
					{{ item.label }}
				</p>
			</li>
		</ul>
		<div
			ref="slider"
			class="slider" />
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
	data() {
		return {
			// 目标元素的位置数据
			offsetList: []
		}
	},
	computed: {
		...mapState('tab', ['activeTab', 'tabData'])
	},
	watch: {
		activeTab: {
			handler() {
				this.setSlider()
			},
			immediate: true
		}
	},
	mounted() {
		this.setSlider()
		const eventListener = () => {
			this.setSlider()
		}
		window.addEventListener('resize', eventListener)
		this.$once('hook:beforeDestroy', () => {
			window.removeEventListener('resize', eventListener)
		})
	},
	methods: {
		...mapMutations('tab', ['SET_ACTIVE_TAB']),
		// 获取位置数据
		getOffsetList() {
			const { tabWrapper } = this.$refs
			let offsetList = []
			if (tabWrapper) {
				const contenList = [
					...tabWrapper.querySelectorAll('.tab-item .content')
				]
				offsetList = contenList.map(content => {
					// 目标元素的标识
					const { value } = content.dataset
					// 目标元素的本身大小及其距离父元素的偏移
					const { offsetWidth, offsetHeight, offsetTop, offsetLeft } = content
					// 目标父元素距离祖先元素的偏移
					const { offsetTop: parentOffsetTop, offsetLeft: parentOffsetLeft } =
						content.parentNode
					// 总偏移
					const totalOffsetTop = offsetTop + parentOffsetTop
					const totalOffsetLeft = offsetLeft + parentOffsetLeft
					return {
						value,
						offsetWidth,
						offsetHeight,
						offsetTop,
						offsetLeft,
						parentOffsetTop,
						parentOffsetLeft,
						totalOffsetTop,
						totalOffsetLeft
					}
				})
			}
			return offsetList
		},
		// 根据选中的tab设置位置和大小
		setOffset(activeTab, offsetList) {
			const { slider } = this.$refs
			if (slider) {
				const target = offsetList.find(item => item.value === activeTab)
				if (target) {
					slider.style.width = `${target.offsetWidth}px`
					slider.style.height = `${target.offsetHeight}px`
					slider.style.top = `${target.totalOffsetTop}px`
					slider.style.left = `${target.totalOffsetLeft}px`
				} else {
					// 如果activeTab不是tabData的数据之一 则清除slider
					slider.style.width = 0
					slider.style.height = 0
					slider.style.left = 0
				}
			}
		},
		// 设置slider
		setSlider() {
			this.offsetList = this.getOffsetList()
			this.setOffset(this.activeTab, this.offsetList)
		},
		// 切换tab
		handleToggleTab({ value }) {
			this.SET_ACTIVE_TAB(value)
			this.$router.push({
				name: value
			})
		}
	}
}
</script>

<style scoped lang="less">
.tab {
	position: relative;
	user-select: none;
	.tab-wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 78px;
		background: linear-gradient(90deg, #313cb0 3%, #3c4ae5 100%);
		border-radius: 10px;
		border: 1px solid #313cb0;
		font-size: 18px;
		color: #ffffff;
		line-height: 18px;
		.tab-item {
			position: relative;
			z-index: 2;
			flex: 1;
			min-width: 180px;
			padding: 0 10px;
			text-align: center;
			&::after {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				content: '';
				width: 1px;
				height: 30px;
				background: rgba(217, 235, 252, 0.6);
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
			.content {
				display: inline-block;
				padding: 14px 44px;
				cursor: pointer;
			}
		}
	}
	.slider {
		position: absolute;
		z-index: 1;
		width: 0;
		height: 0;
		background: #525fee;
		border-radius: 10px;
		transition:
			top 0.5s ease,
			left 0.5s ease;
	}
}
</style>
