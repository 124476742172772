import {
	getSoldProgress,
	getSalesInfo
} from '@/api'

export default {
	namespaced: true,
	state: {
		soldProgress: '0',
		goodsInfo: {}
	},
	mutations: {
		SET_SOLD_PROGRESS(state, soldProgress) {
			state.soldProgress = (soldProgress * 100 || 0).toFixed(2)
		},
		SET_GOODS_INFO(state, goodsInfo) {
			state.goodsInfo = goodsInfo || {}
		}
	},
	actions: {
		getSoldProgress({ commit, state }) {
			getSoldProgress({}).then(res => {
				commit('SET_SOLD_PROGRESS', res ? Number(Number(res).toFixed(4)) : res)
			}).catch(_ => {
				commit('SET_SOLD_PROGRESS', {})
			})
		},
		getSalesInfo({ commit, state }) {
			getSalesInfo({}).then(res => {
				commit('SET_GOODS_INFO', res)
			}).catch(_ => {
				commit('SET_GOODS_INFO', 0)
			})
		}
	}
}
