import api from '@/utils/http'
import { BASE_URL } from './url.base'

// 销售进度
export const getSoldProgress = (params = {}, config = {}) => {
	return api.get(
		`${BASE_URL}/cube/soldProgress`,
		params,
		config
	)
}
// 商品信息
export const getSalesInfo = (params = {}, config = {}) => {
	return api.get(
		`${BASE_URL}/cube/salesInfo`,
		params,
		config
	)
}
// 用户登录
export const userLogin = (params = {}, config = {}) => {
	return api.post(
		`${BASE_URL}/users/login`,
		params,
		config
	)
}
// 验证邀请码
export const verifyPromoCode = (params = {}, config = {}) => {
	return api.post(
		`${BASE_URL}/users/verifyPromoCode`,
		params,
		config
	)
}
// 提交订单
export const submitOrder = (params = {}, config = {}) => {
	return api.post(
		`${BASE_URL}/cube/orders`,
		params,
		config
	)
}
// 订单列表
export const getHistoryOrders = (params = {}, config = {}) => {
	return api.post(
		`${BASE_URL}/cube/getHistoryOrders`,
		params,
		config
	)
}
// 订单列表
export const getLotteryHash = (params = {}, config = {}) => {
	return api.get(
		`${BASE_URL}/lottery/getLotteryHash`,
		params,
		config
	)
}
