export default [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/layout'),
		meta: {
			loginAuth: true
		},
		redirect: '/home',
		// 承载需要layout的公共路由
		children: [
			{
				path: '/home',
				name: 'Home',
				meta: {
					title: 'GPD Labs'
				},
				component: () => import('@/views/home/Index')
			},
			{
				path: '/learn-more',
				name: 'LearnMore',
				meta: {
					title: 'GPD Labs'
				},
				component: () => import('@/views/learn/Index')
			},
			{
				path: '/intro',
				name: 'Intro',
				meta: {
					title: 'GPD Labs'
				},
				component: () => import('@/views/intro/Index')
			},
			{
				path: '/purchase',
				name: 'Purchase',
				meta: {
					title: 'GPD Labs'
				},
				component: () => import('@/views/purchase/Index')
			}
		]
	}
]
