import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式
import '@/assets/styles/global.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/less/global/index.js'
import '@/assets/styles/font.css'
// 插件
import '@/plugins/vconsole'

// 按需引入 element-ui
import { Dialog, Loading, InputNumber } from 'element-ui'
// 自定义element组件样式
// import '@/assets/styles/element/index.js'
// 全局组件
import '@/components'
// 全局指令
import '@/utils/directives'
import 'components/common/toast/index.js'
import { Popup } from '@femessage/vant'

Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(InputNumber)
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
