<template>
	<div class="layout-footer">
		<img
			src="@/assets/image/logo.png"
			alt="" />
		<div class="content">
			<div class="common-btn">Terms of Use</div>
			<div class="common-btn">Privacy Policy</div>
			<ul>
				<li @click="handleOpen('https://x.com/GpdLabs')">
					<img
						src="@/assets/image/twitter.png"
						alt="" />
				</li>
				<li @click="handleOpen('https://t.me/GPD_Labs')">
					<img
						src="@/assets/image/telegram.png"
						alt="" />
				</li>
				<li @click="handleOpen('https://medium.com/@gpd_labs')">
					<img
						src="@/assets/image/M.png"
						alt="" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {
		handleOpen(url) {
			window.open(url)
		}
	}
}
</script>

<style scoped lang="less">
.layout-footer {
	width: 1500px;
	margin: 0 auto;
	padding: 130px 160px 500px;
	display: flex;
	flex-direction: column;
	> img {
		width: 130px;
		margin-bottom: 60px;
	}
	.content {
		display: flex;
		align-items: center;
		.common-btn {
			width: 156px;
			height: 50px;
			border-radius: 25px;
			font-size: 14px;
			margin-right: 50px;
		}
		ul {
			margin-left: auto;
			width: 130px;
			display: flex;
			li {
				flex: 1;
				display: flex;
				justify-content: center;
				img {
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.layout-footer {
		width: 100%;
		padding: 30px 24px 100px;

		> img {
			width: 60px;
			margin-bottom: 30px;
		}
		.content {
			.common-btn {
				width: 100px;
				height: 26px;
				border-radius: 13px;
				font-size: 12px;
				margin-right: 10px;
			}
			ul {
				margin-left: auto;
				width: 80px;
				display: flex;
				li {
					flex: 1;
					display: flex;
					justify-content: center;
					img {
						width: 14px;
						height: 14px;
						cursor: pointer;
					}
				}
			}
		}
	}
}
</style>
