import { getOffsetTop } from '@/utils/viewport'
import { isMobile } from '@/utils/utils'

const headerHeight = 64

function scrollIntoView(el) {
	const offsetTop = getOffsetTop(el)
	const top = isMobile() ? offsetTop - headerHeight : offsetTop
	window.scrollTo({
		top,
		behavior: 'smooth'
	})
}

export default {
	// bind设置样式
	bind(el) {},
	// inserted执行函数
	inserted(el, binding, vnode) {
		const { value } = binding
		if (!value) {
			scrollIntoView(el)
		} else if (value === window.$myView) {
			// 自定义了一个全局变量window.$myView
			scrollIntoView(el)
		}
	}
}
