<template>
	<div
		id="app"
		class="custom-font">
		<transition :name="transitionName">
			<router-view />
		</transition>
	</div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
	data() {
		return {
			transitionName: ''
		}
	},
	watch:{
		$route(to, from){
			//实现路由跳转动画
			if (to.meta.index > from.meta.index)
				this.transitionName = "slide-left";
			if (to.meta.index < from.meta.index)
				this.transitionName = "slide-right";
		}
	},
	mounted() {
		// 页面刷新时 不停留在当前位置 返回页面顶部。该方式主要是为了使得getBoundingClientRect方法获取的距离是正确的初始化数值。
		if (history.scrollRestoration) {
			history.scrollRestoration = 'manual'
		}
	},
  created() {
    this.getSoldProgressFuc()
    this.getSalesInfoFuc()
  },
  methods: {
		...mapActions('goods', ['getSoldProgress', 'getSalesInfo']),
    getSoldProgressFuc() {
			this.getSoldProgress()
    },
    getSalesInfoFuc() {
			this.getSalesInfo()
    }
  }
}
</script>

<style lang="less">
wui-flex {
	position: relative;
	z-index: 99999;
}

:host {
	w3m-email-login-widget {
		display: none !important;
	}
}
w3m-modal::part(w3m-email-login-widget) {
	display: none !important;
}
w3m-modal::part(wui-flex) {
	display: none !important;
}
html,
body {
	width: 100%;
	// 去除移动端点击元素时产生的阴影效果
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;

	w3m-modal {
		z-index: 999999;
		::part(w3m-email-login-widget) {
			display: none !important;
		}
		::part(wui-flex) {
			display: none !important;
		}
		:host {
			w3m-email-login-widget {
				display: none !important;
			}
		}
	}
}
#app {
	min-width: 1500px;
	min-height: 100vh;
	background-color: #000;
	font-size: 24px;
	font-family: Inter, Inter;
	// font-family: Homenaje, Homenaje;
	font-weight: 400;
}

.slide-right-enter-active,.slide-right-leave-active,.slide-left-enter-active,.slide-left-leave-active {
  will-change: transform;
  transition: all .5s;
  position: absolute;
}
.slide-right-enter {
  opacity: 0;
  transform: translate(-100%);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}

@media screen and (max-width: 768px) {
	#app {
		min-width: 100%;
		max-width: 100%;
	}
}
</style>
