<template>
	<el-dialog
		:visible="visible"
		:close-on-click-modal="true"
		:show-close="true"
		class="address-dialog"
		@close="$emit('close')">
		<div class="content">
			<img src="@/assets/image/logo1.png" alt="">
			<h1 class="address">
				{{ addressRules(walletAddress) }}
			</h1>
			<!-- <h2>
				0 ETH
			</h2> -->
			<div class="group-btn">
				<div
					v-clipboard:success="handleCopy"
          v-clipboard:copy="walletAddress"
					class="common-btn">
					Copy Adress
				</div>
				<div class="common-btn" @click="handleDisconnect">
					Disconnect
				</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { addressRules } from '@/utils/utils'
import { Message } from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import Vue from 'vue'

Vue.use(VueClipboard)

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			addressRules
		}
	},
	computed: {
		...mapState('app', ['walletAddress', 'chainId'])
	},
	mounted() {

	},
	methods: {
		...mapMutations('app', ['SET_WALLET_ADDRESS', 'SET_CHAIN_ID']),
		handleDisconnect() {
			this.SET_WALLET_ADDRESS('')
			this.SET_CHAIN_ID('')
			this.$emit('close')
		},
		handleCopy() {
			Message.success('Copy Success')
		}
	}
}
</script>
<style lang="less" scoped>
.address-dialog {
	font-family: 'Homenaje-Regular';
	::v-deep .el-dialog {
		margin-top: 30vh;
		.el-dialog__header {
			padding: 0;
		}
		width: 430px;
		height: 270px;
		background: linear-gradient(180deg, #292929 63%, #0d0d0d 100%);
		border-radius: 20px;
		border: 1px solid #fff;
		.el-dialog__body {
			width: 100%;
			height: 100%;
			padding: 0;
		}
		.content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #fff;

			> img {
				width: 36px;
				height: 36px;
				margin-bottom: 18px;
			}
			h1 {
				font-size: 32px;
				margin-bottom: 30px;
			}
			h2 {
				color: #A49D9D;
				font-size: 26px;
				margin: 10px 0 20px;
			}
			.group-btn {
				display: flex;
				width: 100%;
				justify-content: center;
				margin-top: 20px;
				.common-btn {
					width: 136px;
					height: 40px;
					font-size: 24px;
					border: 1px solid #fff;
					&:nth-child(1) {
						margin-right: 30px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.address-dialog {
		::v-deep .el-dialog {
			margin-top: 60px;
			width: 240px;
			height: 180px;
			.content {

				> img {
					width: 26px;
					height: 26px;
					margin-bottom: 10px;
				}
				h1 {
					font-size: 18px;
					margin-bottom: 20px;
				}
				h2 {
					font-size: 14px;
					margin: 10px 0 15px;
				}
				.group-btn {
					margin-top: 10px;
					.common-btn {
						width: 80px;
						height: 20px;
						font-size: 12px;
						&:nth-child(1) {
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
}
</style>
