<template>
	<div class="wallet-dialog">
		<el-dialog
			:visible="visible"
			:close-on-click-modal="true"
			:show-close="true"
			class="connect-wallet-dialog"
			@close="$emit('close')">
			<div
				v-loading="connectLoading"
				class="content">
				<template v-if="showConnectWallet">
					<h1>WALLET CONNECT</h1>
					<!-- <qrcode
						ref="qrcode"
						:value="connectWalletUrl"
						:size="190"
						class="qrcode-image" /> -->
					<div id="qrcode" ref="qrcode" class="qrcode-image" />
					<div class="scan-tip">Scan this QR code with your phone</div>
					<h3 class="sub-title">Copy Link</h3>
				</template>
				<template v-else>
					<h1>CONNECT WALLET</h1>
					<h2>Recommend</h2>
					<ul class="connect-list">
						<li @click="handleConnectWallet">
							<img src="@/assets/image/header/metamask.png" />
							<span>Matamask</span>
						</li>
						<li @click="handleConnectWallet">
							<img src="@/assets/image/header/ok.png" />
							<span>OKX Wallet</span>
						</li>
						<li @click="connectWithWalletConnect">
							<img src="@/assets/image/header/scan.png" />
							<span>Wallet Connect</span>
						</li>
					</ul>
					<h3 class="sub-title">Acquire Wallet</h3>
				</template>
			</div>
		</el-dialog>

		<el-dialog
			:visible="statusVisible"
			:close-on-click-modal="true"
			:show-close="true"
			class="status-dialog">
			<div class="status-content">
				<template v-if="status === 'success'">
					<span>SUCCESS</span>
					<img src="@/assets/image/header/success.png" />
				</template>
				<template v-else>
					<span>FAIL</span>
					<img src="@/assets/image/header/fail.png" />
				</template>
			</div>
		</el-dialog>
	</div>
</template>
<script>
/* eslint-disable */
import {
	get_my_account,
	getCurrNetwork,
	switchNetwork
} from '@/utils/smartContractCall/walletCall'
import { supportNetwork } from '@/utils/config'
import { mapMutations, mapState } from 'vuex'
// https://blog.csdn.net/2202_75618418/article/details/140925205
import SignClient from '@walletconnect/sign-client'
import QRCode from 'qrcodejs2-fix'
// import { Message } from 'element-ui'

// import { createAppKit, useAppKit } from '@reown/appkit/vue'
// import { EthersAdapter } from '@reown/appkit-adapter-ethers'
// import { mainnet, arbitrum, polygon } from '@reown/appkit/networks'
export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			connectLoading: false,
			statusVisible: false,
			status: '',
			showConnectWallet: false,
			connectWalletUrl: '',
			approval: null,
			modal: '',
			modalFuc: '',
			timer: null
		}
	},
	computed: {
		...mapState('app', ['walletAddress', 'chainId'])
	},
	watch: {
		visible(val) {
			if (val) {
				// Message.success('Connect wallet success')
				// this.$message({
				// 	message: '恭喜你，这是一条成功消息',
				// 	type: 'success'
				// })
			} else {
				if (this.timer) {
					clearTimeout(this.timer)
					this.timer = null
				}
			}
		}
	},
	async mounted() {
		this.getConnectWallet()
		return
		try {
			// 1. Get projectId from https://cloud.reown.com
			const projectId = '4087b80ead97e4f9ac9c8d6f2aacdc73'
			// 2. Set up Ethers adapter
			const ethersAdapter = new EthersAdapter()
			// 3. Create your application's metadata object
			const metadata = {
				name: 'GPD',
				description: 'GPD Labs',
				url: 'http://localhost:8081/ ', // origin must match your domain & subdomain
				icons: ['https://avatars.githubusercontent.com/u/179229932']
			}
			// 4. Create a AppKit instance
			const modal = createAppKit({
				adapters: [ethersAdapter],
				defaultNetwork: mainnet,
				networks: [mainnet, arbitrum, polygon],
				allWallets: 'HIDE',
				includeWalletIds: [
					'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
					'971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
				],
				// tokens: {
				// 	1: {
				// 		address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
				// 		image: 'token_image_url' //optional
				// 	},
				// 	137: {
				// 		address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
				// 		image: 'token_image_url' //optional
				// 	}
				// },
				metadata,
				projectId,
				features: {
					analytics: true // Optional - defaults to your Cloud configuration
				}
			})
			this.modalFuc = modal
			this.modal = useAppKit()

			// console.log(this.modalFuc)
			// console.log(this.modal)
			// console.log(useAppKitTheme)

			// const handleChange = ({ provider, providerType, address, error, chainId, isConnected }) => {
			// 	console.log(provider, providerType, address, error, chainId, isConnected)
			// }

			// modal.subscribeProvider(handleChange)
		} catch (err) {
			console.log('err', err)
		}
	},
	methods: {
		...mapMutations('app', ['SET_WALLET_ADDRESS', 'SET_CHAIN_ID']),
		async getConnectWallet() {
			const metadata={
				//项目名称
				name: 'GPD',
				//项目解释
				description: 'GPD labs',
				//项目官网
				url: 'http://localhost:8081/',
				//项目图片
				icons: [
					"'https://avatars.githubusercontent.com/u/179229932"
				]
			}
			//项目ID
			const projectId = "4087b80ead97e4f9ac9c8d6f2aacdc73"

			const getSignClient = async() => {
				let signClient = null
				if (!signClient) {
					signClient = await SignClient.init({
						projectId, // 替换为你的项目ID
						metadata
					})
				}
				return signClient
			}
			const signClient = await getSignClient()

			// 创建对等连接获取主题
			const { topic } = await signClient.core.pairing.create()
			// 发送命名空间,获取uri和等待授权的函数
			const { uri, approval } = await signClient.connect({
				pairingTopic: topic,
				//空间方法
				requiredNamespaces: {
					eip155: { methods: ['eth_sign'], chains: ['eip155:1'], events: ['accountsChanged'] },
					// solana: {
					// 	methods: [],
					// 	chains: [
					// 		// "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp"
					// 	],
					// 	events: []
					// }
				}
			})
			console.log('uri', uri, approval)
			this.connectWalletUrl = uri
			this.approval = approval
		},
		async handleConnectWallet() {
			this.connectLoading = true
			const connectRes = await get_my_account().catch(err => {
				this.connectLoading = false
			})
			console.log('connectRes', connectRes)
			if (!connectRes) {
				this.showConnectStatus('fail')
				return
			}
			const currNetworkId = await getCurrNetwork().catch(_ => {
				this.connectLoading = false
			})
			console.log('currNetworkId', currNetworkId)
			const findIndex = supportNetwork.findIndex(
				item => item.chainId === currNetworkId
			)
			if (findIndex < 0) {
				const swicthRes = await switchNetwork(supportNetwork[0].chainId).catch(
					_ => {
						this.connectLoading = false
					}
				)
				if (swicthRes) {
					const chainId = supportNetwork[0].chainId
					this.showConnectStatus('success', connectRes, chainId)
					this.connectLoading = false
				}
			} else {
				const chainId = currNetworkId
				this.showConnectStatus('success', connectRes, chainId)
				this.connectLoading = false
			}
		},
		showConnectStatus(type, walletAddress, chainId) {
			this.statusVisible = true
			this.status = type
			setTimeout(() => {
				this.statusVisible = false
			}, 2000)
			if (type === 'success') {
				this.SET_WALLET_ADDRESS(walletAddress)
				this.SET_CHAIN_ID(chainId)
				this.$emit('success')
			}
		},
		async connectWithWalletConnect() {
			this.showConnectWallet = true
			this.$nextTick(async () => {
				const qrBuffer = new QRCode(this.$refs.qrcode, {
					text: encodeURI(this.connectWalletUrl),
					width: 180,
					height: 180
				})
				console.log('qrBuffer', qrBuffer, this.connectWalletUrl)
				const session = await this.approval().catch(err => {
					console.log('error', err)
				})
				//获取链接的账号
				const account = session.namespaces.eip155.accounts[0].split(':')[2]

			})
			return
			this.modal.open()

			if (this.timer) {
				clearTimeout(this.timer)
			}
			const callBack = () => {
				this.timer = setTimeout(async () => {
					const address = this.modalFuc.getAddress()
					if (address) {
						this.SET_WALLET_ADDRESS(address)
						clearTimeout(this.timer)
						const netWorkId = await getCurrNetwork()
						this.SET_CHAIN_ID(netWorkId)
						this.timer = null
						this.modal.close()
						this.$emit('close')
					} else {
						callBack()
					}
				}, 2000)
			}
			callBack()
		},
		getCurrNetwork() {
			getCurrNetwork()
				.then(res => {
					//
				})
				.catch(err => {})
		}
	}
}
</script>
<style lang="less" scoped>
.status-dialog {
	font-family: 'Homenaje-Regular';
	::v-deep .el-dialog {
		margin-top: 30vh;
		.el-dialog__header {
			padding: 0;
		}
		width: 460px;
		height: 200px;
		background: linear-gradient(180deg, #292929 63%, #0d0d0d 100%);
		border-radius: 20px;
		border: 1px solid #fff;
		.el-dialog__body {
			width: 100%;
			height: 100%;
			padding: 0;
		}
		.status-content {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			span {
				font-size: 45px;
				margin-right: 40px;
			}
			img {
				width: 60px;
				height: 60px;
			}
		}
	}
}
.connect-wallet-dialog {
	font-family: 'Homenaje-Regular';
	letter-spacing: 1px;
	color: #fff;
	z-index: 9999!important;
	::v-deep .el-dialog {
		.el-dialog__header {
			padding: 0;
		}
		width: 380px;
		height: 500px;
		background: linear-gradient(180deg, #292929 63%, #0d0d0d 100%);
		border-radius: 20px;
		border: 1px solid #fff;
		.el-dialog__body {
			width: 100%;
			height: 100%;
			padding: 0;
		}
	}
	::v-deep {
		.el-loading-mask {
			border-radius: 20px;
			background-color: rgba(255, 255, 255, 0.4);
		}
	}
	.sub-title {
		font-size: 18px;
		text-align: center;
		position: relative;
		margin-top: 30px;
		color: #fff;
		&::after {
			content: '';
			width: 100px;
			position: absolute;
			left: 50%;
			bottom: -5px;
			transform: translateX(-50%);
			height: 0;
			border-bottom: 0.5px dashed #fff;
		}
	}
	.content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		h1 {
			font-size: 40px;
			line-height: 48px;
			color: #fff;
		}
		h2 {
			font-size: 30px;
			margin-bottom: 20px;
			margin-top: 10px;
			color: #fff;
		}
		.connect-list {
			li {
				font-size: 18px;
				width: 244px;
				height: 53px;
				border-radius: 40px;
				border: 1px solid #fff;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 24px;
				cursor: pointer;
				&:nth-last-child(1) {
					margin-bottom: 0;
				}
				img {
					width: 37px;
					height: 37px;
					margin-right: 4px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.status-dialog {
		::v-deep .el-dialog {
			margin-top: 60px;
			.el-dialog__header {
				padding: 0;
			}
			width: 240px;
			height: 100px;
			.status-content {
				span {
					font-size: 15px;
					margin-right: 14px;
				}
				img {
					width: 25px;
					height: 25px;
				}
			}
		}
	}
	.connect-wallet-dialog {
		::v-deep .el-dialog {
			width: 240px;
			height: 260px;
		}
		.sub-title {
			font-size: 13px;
			margin-top: 15px;
			&::after {
				content: '';
				width: 80px;
				position: absolute;
				left: 50%;
				bottom: -5px;
				transform: translateX(-50%);
				height: 0;
				border-bottom: 0.5px dashed #fff;
			}
		}
		.content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			h1 {
				font-size: 18px;
				line-height: 24px;
			}
			h2 {
				font-size: 14px;
				margin-bottom: 15px;
				margin-top: 10px;
			}
			.connect-list {
				li {
					font-size: 12px;
					width: 160px;
					height: 24px;
					border-radius: 12px;
					margin-bottom: 10px;
					img {
						width: 12px;
						height: 12px;
						margin-right: 4px;
					}
				}
			}
		}
	}
}
</style>
