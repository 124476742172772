<template>
	<Input
		v-bind="$attrs"
		:autocomplete="$attrs.autocomplete || 'new-text'"
		:placeholder="$attrs.placeholder || '请输入'"
		v-on="$listeners">
		<slot />
	</Input>
</template>

<script>
import { Input } from 'element-ui'
export default {
	components: {
		Input
	},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
