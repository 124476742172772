<template>
	<div
		v-show="visible"
		class="country-list-container">
		<ul
			v-clickoutside="handleClickOutside"
			class="country-list">
			<li
				v-for="item in list"
				:key="item.label"
				:class="{ active: item.value === selectCode }"
				@click="handleSelect(item)">
				<span class="li-left">{{ item.name }}</span>
				<span class="li-right">{{ item.label }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		list: {
			type: Array,
			default() {
				return []
			}
		},
		selectCode: {
			type: [String, Number],
			default: ''
		}
	},
	data() {
		return {}
	},
	methods: {
		handleClickOutside() {
			this.$emit('update:visible', false)
		},
		handleSelect(item) {
			setTimeout(() => {
				this.handleClickOutside()
			})
			this.$emit('update:select-code', item.value)
		}
	}
}
</script>

<style scoped lang="less">
.country-list-container {
	position: absolute;
	top: 48px;
	left: 0px;
	z-index: 100;
	border: 1px solid #d7dbec;
	width: 100%;
	height: 290px;
	box-shadow: 0px 4px 6px 0px rgba(26, 40, 77, 0.1);
	border-radius: 5px;
	background: #fff;
	&:after {
		content: '';
		position: absolute;
		left: 54px;
		top: -5px;
		width: 7px;
		height: 7px;
		border-top: 1px solid #d7dbec;
		border-right: 1px solid #d7dbec;
		border-bottom: 1px solid transparent;
		border-left: 1px solid transparent;
		transform: rotate(-45deg) skew(-10deg, -10deg);
		background: #fff;
	}
	.country-list {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: scroll;
		padding-top: 10px;
		cursor: pointer;
		z-index: 101;
		li {
			position: relative;
			padding: 0 20px 0 30px;
			height: 40px;
			line-height: 40px;
			color: #000000;
			font-size: 15px;
			.li-left {
				float: left;
			}
			.li-right {
				float: right;
				color: #787993;
			}
			&.active {
				&:after {
					content: '';
					position: absolute;
					left: 15px;
					top: 50%;
					width: 3px;
					height: 6px;
					transform: translateY(-95%) rotate(45deg);
					border-right: 2px solid #4d63f8;
					border-bottom: 2px solid #4d63f8;
				}
			}
			&:hover {
				background: #f3f3f9;
			}
		}
	}
}
</style>
