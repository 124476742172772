<template>
	<div class="input-wrapper">
		<div class="slot-left">
			<slot name="left" />
		</div>
		<div
			v-show="!disabled"
			class="input-box"
			:class="inputDisabled ? 'input-disabled' : ''">
			<!-- spellcheck 禁止单词下面划出红色的波浪线  -->
			<input
				ref="input"
				:type="inputType"
				class="input"
				:maxlength="maxLength"
				:placeholder="placeholder"
				:disabled="inputDisabled"
				:value="value"
				spellcheck="false"
				v-on="inputListeners" />
		</div>
		<div
			v-show="disabled"
			class="text">
			{{ value }}
		</div>
		<slot name="right" />
	</div>
</template>

<script>
export default {
	props: {
		// 输入框类型
		type: {
			type: String,
			default: 'text'
		},
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		inputDisabled: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
			default: 999
		},
		// 自定义正则（当现有type不满足需求时，可根据需要进行自定义正则）
		regExp: {
			type: RegExp,
			default: null
		}
	},
	data() {
		return {
			inputType: '',
			reg: null,
			inputFlag: true
		}
	},
	computed: {
		inputListeners: function () {
			const vm = this
			// `Object.assign` 将所有的对象合并为一个新对象
			return Object.assign(
				{},
				// 添加所有的监听器
				this.$listeners,
				// 添加自定义监听器，
				{
					// 解决移动端ios中文输入法多次触发input事件的bug 可参见链接：https://juejin.cn/post/6844903937288437768
					compositionstart: () => {
						this.inputFlag = false
					},
					compositionend: e => {
						this.inputFlag = true
						this.resetInput(vm, e)
					},
					// 这里确保组件配合 `v-model` 的工作
					input: e => {
						if (!this.inputFlag) return
						this.resetInput(vm, e)
					},
					keyup: function (e) {}
				}
			)
		}
	},
	watch: {
		// 监听 type。由于 text 最宽泛，且可设置正则，所以额外的类型利用 text + reg 进行限制。
		type: {
			handler(newValue) {
				this.inputType = 'text'
				// 只支持输入英文 及 空格 英文逗号 英文·
				if (newValue === 'text-en') {
					this.reg = /[^a-zA-Z ,·]/g
					return
				}
				// 只支持输入中文
				if (newValue === 'text-cn') {
					this.reg = /[^\u4e00-\u9fa5]/g
					return
				}
				// 只支持输入数字
				if (newValue === 'text-number') {
					this.reg = /[^0-9]/g
					return
				}
				this.inputType = newValue
			},
			immediate: true
		}
	},
	methods: {
		// 利用正则表达式 重置输入框
		resetInput(vm, e) {
			const { reg, regExp } = vm
			// 将不合格式的输入 替换成空字符
			if (regExp || reg) {
				e.target.value = e.target.value.replace(regExp || reg, '')
			}
			vm.$emit('input', e.target.value)
		}
	}
}
</script>

<style scoped lang="less">
.label {
	padding-top: 10px;
	font-size: 14px;
	font-family:
		PingFangSC-Regular,
		PingFang SC;
	font-weight: 400;
	color: #666e80;
	line-height: 20px;
}
.input-wrapper {
	position: relative;
	margin: auto 0;
	position: relative;
	display: flex;
	align-items: center;
	.slot-left {
		font-size: 14px;
		font-family:
			PingFangSC-Regular,
			PingFang SC;
		font-weight: 400;
		color: #666e80;
		line-height: 20px;
	}
	.input-box {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1;
		background-color: inherit;
		.input {
			width: 100%;
			font-size: 14px;
			background-color: inherit;
		}
	}
	.input-disabled {
		background: #f9f9f9;
		> input {
			background: #f9f9f9;
		}
	}
	.text {
		flex: 1;
		margin-left: 20px;
		height: 45px;
		padding: 10px 0px;
		border-radius: 3px;
		font-size: 14px;
		font-family:
			PingFangSC-Regular,
			PingFang SC;
		font-weight: 400;
		color: #2e384d;
		line-height: 25px;
	}
}
</style>
