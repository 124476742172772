import { supportNetwork } from '../config'
import Web3 from 'web3'

const token_abi = require('@/abis/TT.json')
const new_web3 = (window.new_web3 = new Web3(Web3.givenProvider || null))
// const contract_USDT = new new_web3.eth.Contract(token_abi, USDT.address)
const bn = new_web3.utils.toBN

const contractObj = {}
supportNetwork.map(e => {
	const { USDT, USDC, chainId } = e
	if (USDT) {
		contractObj[`${chainId}_USDT`] = new new_web3.eth.Contract(token_abi, USDT)
		contractObj[`${chainId}_USDC`] = new new_web3.eth.Contract(token_abi, USDC)
	}
})

const getCurrContract = (chainId, token) => {
	if (!token || !chainId) return null
	return contractObj[`${chainId}_${token}`] || null
}

export {
	contractObj,
	getCurrContract,
	bn
}
