import CryptoJS from 'crypto-js'
// import qs from 'qs'
import store from '@/store'

// 脱敏
const md5 = str => {
	return CryptoJS.MD5(str).toString()
}

// 签名
const getSign = (params, md5Pwd = '', signKeys) => {
	if (!md5Pwd) {
		return
	}
	// 按字母表排序
	const sortedKeys = Object.keys(params).sort()
	const sortedArray = []
	for (const key of sortedKeys) {
		// 过滤字段
		if (signKeys.includes(key)) {
			const value = Array.isArray(params[key])
				? params[key].join(',')
				: params[key]
			sortedArray.push({
				key,
				value,
				encoded: `${key}=${value}`
			})
		}
	}
	const { userId } = store.state.app.userInfo
	const signStr =
		sortedArray.map(item => item.encoded).join('&') +
		md5(`${userId}${md5Pwd}`).toUpperCase()
	const sign = md5(signStr).toUpperCase()
	if (process.env.VUE_APP_TARGET !== 'PROD') {
		console.log(
			sortedArray,
			md5Pwd,
			md5(`${userId}${md5Pwd}`).toUpperCase(),
			signStr,
			sign
		)
	}
	return sign
}

// 批量更改请求
const transformPostParams = (params, config) => {
	const { md5Keys = '', signKeys = [] } = config
	if (md5Keys) {
		if (Array.isArray(md5Keys)) {
			md5Keys.forEach(md5Key => {
				params[md5Key] && (params[md5Key] = md5(params[md5Key]))
			})
		} else {
			// 当作字符串
			params[md5Keys] && (params[md5Keys] = md5(params[md5Keys]))
		}
		if (signKeys && signKeys.length) {
			params.sign = getSign(params, params[md5Keys], signKeys)
			delete params[md5Keys]
		}
	}
	return params
}

export default {
	md5,
	getSign,
	transformPostParams
}
