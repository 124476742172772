import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'
// import router from '@/router'
import secure from '@/utils/http/secure'


// 创建axios实例
const service = axios.create({
	// baseURL: process.env.BASE_API, // api 的 base_url
	// baseURL: '/oauth-app',
	timeout: 50000 // 请求超时时间
})

// response 拦截器
service.interceptors.response.use(
	async response => {
		const { data } = response
		if (data.code !== 200) {
			Message.error(data.reason)
			Promise.reject(data)
		} else {
			// Promise.resolve(data.result)
			return data.result
		}
	},
	error => {
		console.error(error)
		Message.error(error.message)
		return Promise.reject(error)
	}
)

// 由于axios的get和post方法传递参数有差异 进行二次封装下
const get = (url, params, config) => {
	// 可利用paramsSerializer对params参数序列化
	return service({
		url,
		method: 'get',
		params,
		...config
	})
}
const post = (url, params, config = {}, dataType = 'JSON') => {
	// 密码脱敏和交易签名
	let data = secure.transformPostParams(params, config)
	// 这里做一层FormData URLEncoded兼容判断
	if (dataType === 'URLEncoded') {
		// 利用qs对参数进行编码，并设置Content-Type为application/x-www-form-urlencoded
		if (Object.values(params).some(Array.isArray)) {
			// 存在值为数组的参数
			let queryString = ''
			const keys = Object.keys(params)
			keys.forEach(key => {
				if (Array.isArray(params[key])) {
					params[key].forEach(subValue => {
						queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
							subValue
						)}&`
					})
				} else {
					queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
						params[key]
					)}&`
				}
			})
			// 去除末尾&
			data = queryString.slice(0, -1)
		} else {
			data = qs.stringify(params)
		}

		const headers = config.headers || {}
		config = {
			...config,
			headers: {
				...headers,
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
			}
		}
	}
	if (dataType === 'FormData') {
		data = new FormData()
		for (const k in params) {
			data.append(k, params[k])
		}
	}
	return service({
		url,
		method: 'post',
		data,
		...config
	})
}

const file = (url, formData, config) => {
	return service({
		url,
		method: 'post',
		// data: {
		//   ...params
		// },
		// 如果是formData形式的post请求 注意这里的参数形式。因为new FormData()后即使执行append，实例formData依然是一个空对象。如果利用上面es6的语法，参数data自然也是空对象。理想情况应该是file：(binary)。
		data: formData,
		...config
	})
}

export default {
	get,
	post,
	file
}
